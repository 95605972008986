html,
body {
  height: 100%;
  font-family: "light";
  scroll-behavior: smooth;
  padding: 0;

}

.contact {
  display: flex;
  /* Enables flexbox layout */
  flex-direction: column;
  /* Stack elements vertically (optional, depending on your design) */
  align-items: center;
  /* Horizontally center the children (ContactForm and ContactGrid) */
  justify-content: center;
  /* Vertically center the children (optional) */
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Default 3 columns */
  gap: 60px;
  /* Space between items */
  row-gap: 70px;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 60px;
  padding-bottom: 15px;
  margin-bottom: 30px;
  ;
}

.feature-item.visible {
  opacity: 1;
  /* Fully visible */
  transform: translateY(0);
  /* Back to original position */
}

.services-list li {
  opacity: 0;
  /* Initially hidden */
  transform: translateY(20px);
  /* Slight downward offset */
  transition: opacity 0.6s ease, transform 0.6s ease;
  /* Smooth animation */
}

.services-list li.visible {
  opacity: 1;
  /* Fully visible */
  transform: translateY(0);
  /* Reset to original position */
}

.spacer-div-30px {
  width: 100%;
  height: 30px;
  ;
}

.feature-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  background-color: #ffffff53;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
  padding: 4%;
  flex-direction: column;
  position: relative;
  align-items: center;
  /* Center horizontally */
  text-align: center;
  /* Center text */
}

.feature-item .service-item-title {
  font-size: 25px;
}

.contact-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center content horizontally */
  justify-content: flex-start;
  /* Align content to the top */
  text-align: center;
  /* Center text */
  padding: 4%;
  position: relative;
  margin-top: 80px;
}

.contact-tile img {
  width: auto;
  /* Maintain aspect ratio */
  height: 200px;
  /* Set consistent height for all images */
  object-fit: cover;
  /* Ensure the image fills the set height without distortion */
  border-radius: 50%;
  /* Optional: Make images circular */
}

.contact-tile h3 {
  margin-top: 20px;
  /* Add consistent spacing after image */
  margin-bottom: 10px;
  /* Add consistent spacing before paragraph */
  font-family: light;
}

.contact-tile p {
  font-family: light;
  font-size: 19px;
  margin: 0;
  /* Remove default margin for consistency */
}

/* Ensure all tiles are the same height for uniformity */
.contact-tile {
  height: 300px;
  /* Adjust to fit content consistently */
}

.feature-icon {
  display: block;
  margin: 40px;
  /* Ensure the image is centered horizontally */
  height: 120px;
  ;
}

.feature-item p {
  font-size: 17px;
  font-family: "Light";
  color: #000000;
  margin-top: 10px;
  text-align: justify;
  /* Justifies the text, spreading it evenly */
  text-justify: inter-word;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}



.it-partner-section {
  font-family: light;
  margin-top: 30px;
  font-size: 21px;
  margin-left: 29%;
  margin-right: 29%;
}

.it-partner-section p {
  font-size: 21px;
  
}

.services-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.services-list li {
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.516);
  padding: 3px;
  border-radius: 10px;

}

.list-title {
  display: flex;
  /* Align icon and title horizontally */
  font-size: 25px;
  align-items: center;
  /* Align icon and text vertically */
  margin-bottom: 5px;
  /* Space between title and description */
}

.list-title .icon {
  font-size: 20px;
  /* Icon size */
  color: #007bff;
  /* Icon color */
  margin-right: 10px;
  /* Space between icon and text */
}

.list-description {
  text-align: justify;
  /* Optional: Justify text for a clean look */
  color: #333;
}


.skillset-container {
  margin-top: 30px;
  margin-left: 25%;
  margin-right: 25%;
  text-align: justify;
  /* Justifies the text, spreading it evenly */
  text-justify: inter-word;
}

.skillset-container p{
font-size: 22px;
}

.skillset {
  display: grid;
  grid-template-rows: repeat(2, auto);
  /* Force two rows */
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  /* Distribute columns equally */
  gap: 20px;
  /* Space between items */
  justify-items: center;
  /* Center each icon in its cell */
  padding: 20px;
}


.mail-link-color {
  text-decoration: none;
  color: #79abfc;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.profile-picture-janni {
  width: 270px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.profile-picture-andrei {
  width: 230px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.profile-picture-tim {
  width: 220px;
  border-radius: 50%;
  margin-bottom: 15px;
}


.nav-link {
  transition: all 0.5s ease;
  color: #fff;
  font-family: "Light";
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  background-color: transparent;
  padding: 10px;
  outline: none;
  border-radius: 4px;
  margin-right: 30px;
  vertical-align: middle;
}

.page-content {
  z-index: 99;
  margin-top: 3%;
  margin-bottom: 3%;
  animation: fadeIn 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Align content vertically */
  align-items: center;
  /* Align content horizontally (optional, for center alignment) */
}

@font-face {
  font-family: "Light";
  /*Can be any text*/
  src: local("AltonaSansRegular-6YBqq"),
    url("./fonts/AltonaSansRegular-6YBqq.ttf") format("truetype");
}

@media screen and (max-width: 1200px) {
  .about-us-tiles {
    flex-direction: column;
  }

  .it-partner-section {
    font-family: light;
    margin-top: 20px;
    font-size: 18px;
    margin-left: 0%;
    margin-right: 0%;
  }

  .skillset-container {
    margin-left: 5%;
    margin-right: 5%;

  }

  .features-grid {

    row-gap: 30px;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 40px;
    padding-bottom: 15px;
  }

  .features-grid {
    grid-template-columns: repeat(1, 1fr);
  }



  .navbar-collapse {
    background-color: #ffffff;
    text-align: center;
  }

  .page-content {
    margin-top: 60px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 3%;
  }

}

.home {
  z-index: 99;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure the container fills the viewport height */
}