.progressive-image-container {
  position: relative;
  width: 100%;
  /* Match the width of the parent */
  height: 180px;
  /* Ensure consistent height */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;

}

.prgressive-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.spinner-container {
  position: absolute;
  inset: 0;
  background-color: rgba(240, 240, 240, 0.6); /* semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
