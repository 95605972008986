.banner-img-container {
  position: relative;
  /* Enables stacking child elements */
  width: 100vw;
  /* Full width of the viewport */
  height: 30vh;
  /* 50% of the viewport height */
  overflow: hidden;
  /* Ensures content does not overflow */
}

.banner-img {
  position: absolute;
  /* Places the background layer */
  top: 0;
  left: 0;
  width: 100%;
  /* Full width of the container */
  height: 100%;
  /* Full height of the container */
  background-size: cover;
  /* Ensures the image always covers the full width and height */
  background-position: left center;
  /* Keeps the left side fixed */
  background-repeat: no-repeat;
  /* Prevents repetition of the image */

  /* Combined transparency masks */
  -webkit-mask-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.5) 85%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(to right, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.5) 85%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(to left, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.3) 85%, rgba(0, 0, 0, 0) 100%);
  /* Vertical gradient */
  /* Vertical gradient */
  -webkit-mask-size: 100% 100%;
  /* Ensures the mask covers the full image */
  -webkit-mask-composite: destination-in;
  /* Combines the masks */
  mask-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.5) 85%, rgba(0, 0, 0, 0) 100%),
    /* Vertical gradient */
    linear-gradient(to right, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.3) 85%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(to left, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.3) 85%, rgba(0, 0, 0, 0) 100%);
  /* Vertical gradient */

  mask-size: 100% 100%;
  /* Ensures the mask covers the full image */
  mask-composite: intersect;
  /* Combines the masks */
}

.banner-overlay-text {
  position: absolute;
  top: 20%;
  right: 20%;
  z-index: 1;
  color: rgb(190, 190, 190);
  font-family: "Light";
  font-size: 27px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Ensures right alignment */
}

.banner-overlay-text .fade-in {
  opacity: 0.85;
  padding-right: 6px;
}

.banner-overlay-text .first,
.banner-overlay-text .second {
  display: inline-block; /* Each block takes its own width */
  padding: 2px 3px; /* Adjust padding as needed */
  max-width: fit-content; /* Ensures the box wraps around content */
  white-space: wrap; /* Prevents breaking onto new lines */
  margin-left:15px;
}

.banner-overlay-text .first {
  animation-delay: 3s;
  color: rgb(92, 92, 92);
  background-color: rgba(255, 255, 255, 0.909);
}

.banner-overlay-text .second {
  animation-delay: 1s;
  color: rgb(92, 92, 92);
  background-color: rgba(255, 255, 255, 0.909);
}


.spacer10 {
  height: 10px;
}


.banner-overlay-text hr {
  border: 0;
  /* Removes default border styling */
  height: 3px;
  /* Sets thickness to 3px */
  background-color: black;
  /* Sets the color to black */
  width: 100%;
  /* Optional: Adjust width as needed */
  margin: 10px 0;
  /* Adds spacing around the line */
}



@media screen and (max-width: 1200px) {


  .banner-overlay-text {
    position: absolute;
    /* Positions the text relative to the parent container */
    top: 5%;
    /* Adjust as needed to place it close to the top */
    right: 10%;
    /* Adjust as needed to place it close to the right */
    z-index: 1;
    /* Ensures the text appears above the background */
    color: #04003b;
    /* Text color */
    font-family: "Light";
    /* Font family */

    /* Fully transparent at the outer edges */
    font-size: 25px;
    text-align: right;
    /* Aligns the text content to the right if multiline */
  }
}